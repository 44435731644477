<script>
import {
  unref,
  h,
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  computed,
  nextTick,
  toRefs,
  watch,
} from 'vue';
import random from 'lodash/random';
import IconClose from '@/components/icon-svg/IconClose.vue';
import BaseLoader from '@/components/BaseComponents/BaseLoader.vue';
import { isAppleDevice } from '@/utils/bowser';
import useBaseField from '@/composables/use-base-field';
import baseFieldMixin from './mixins/base-field.mixin';

export default defineComponent({
  name: 'BaseInput',
  mixins: [baseFieldMixin],
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default() {
        return 'text';
      },
    },
    role: {
      type: String,
      default() {
        return 'none';
      },
    },
    step: {
      type: String,
      default: '1',
    },
    autocomplete: { type: String, default: '' },
    min: { type: String, default: '' },
    max: { type: String, default: '' },
    mask: {
      type: [String, Object],
      default: null,
    },
    fullHeight: {
      type: Boolean,
      default() {
        return false;
      },
    },
    errorMessageFixed: {
      type: Boolean,
      default() {
        return false;
      },
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    decimal: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    isDisabledTrim: {
      type: Boolean,
    },
  },
  emits: [
    'focus',
    'blur',
    'change',
    'input',
    'keydownDown',
    'keydownUp',
    'keydownEnter',
    'keydown',
    'clear',
    'update:modelValue',
  ],
  setup(props, { emit, attrs, slots }) {
    const { modelValue } = toRefs(props);

    const field = ref(null);

    const {
      baseClassName,
      rootClasses,
      fieldAttrs,
      hasSlotIcon,
      groutClasses,
      getPlaceholder,
      getError,
      getAdditionalInfo,
      focused,
      isIOs,
      focus,
    } = useBaseField(props, { slots }, field);

    const ellipsis = ref(true);
    const inputMask = ref(null);
    const inputMaskElement = ref(null);
    const randomValue = ref(`form_random-${random(-1000, 1000)}-${random(-1000, 1000)}`);

    const setEllipsis = (value) => {
      if (!props.readonly) {
        ellipsis.value = value;
      }
    };

    const destroyMask = () => {
      unref(inputMaskElement) && unref(inputMaskElement).remove();
      inputMaskElement.value = null;
    };

    const initMask = () => {
      if (unref(inputMask)) {
        inputMaskElement.value = unref(inputMask).mask(field.value);
      }
    };

    onMounted(() => {
      if (props.mask) {
        import('inputmask').then((Inputmask) => {
          inputMask.value = new Inputmask.default(props.mask);
          inputMask.value.opacity = {
            onBeforePaste: (pastedValue) => {
              const newTel = pastedValue.replace(/^(\+7|8)(.*?)$/g, '$2');
              return newTel;
            },
            showMaskOnHover: false,
          };

          initMask();
        });
      }

      isIOs.value = isAppleDevice();
      ellipsis.value = !unref(isIOs);
    });

    onUnmounted(() => {
      inputMask.value = null;
      if (unref(inputMaskElement)) {
        destroyMask();
      }
    });

    const inputListeners = computed(() => {
      return {
        onFocus(event) {
          initMask();
          if (unref(isIOs)) {
            setEllipsis(false);
          }
          focused.value = true;
          emit('focus', event);
        },
        onBlur(event) {
          // If mask plugin is applied and input.value is empty string, placeholder remains visible
          // Force setting input.value to null resolves issue
          if (props.mask && event.target.value.length === 0) {
            event.target.value = null;
          }
          if (!unref(isIOs)) {
            setEllipsis(true);
          }
          focused.value = false;
          emit('blur', event);

          // Почему-то библиотека inputMask не триггерит ивент onchange, колхоз
          if (props.mask) {
            emit('change', event.target.value, event);
          }

          destroyMask();
        },
        onInput(event) {
          let value = event.target.value;

          if (!props.isDisabledTrim) {
            value = value.trim();
          }

          if (props.type === 'number') {
            value = value.replace(/\+|-/gi, '');
          }

          emit('update:modelValue', value, event);
          emit('input', value, event);
        },
        onKeydown(event) {
          if (props.type === 'number') {
            const { key } = event;

            if (key === '+' || key === '-') {
              event.preventDefault();
            }

            if (props.decimal && (key === '.' || key === ',')) {
              event.preventDefault();
            }
          }

          if (event.keyCode === 40) {
            emit('keydownDown', event);
          }
          if (event.keyCode === 38) {
            emit('keydownUp', event);
          }
          if (event.keyCode === 13) {
            emit('keydownEnter', event);
          }
          if (event.keyCode === 27) {
            emit('keydownEnter', event);
          }
          emit('keydown', event);
        },
        onChange(event) {
          let value = event.target.value;

          if (!props.isDisabledTrim) {
            value = value.trim();
          }

          if (props.type === 'number') {
            value = value.replace(/\+|-/gi, '');
          }

          emit('change', value);
        },
      };
    });

    const inputAttrs = computed(() => {
      const attributs = {
        type: props.type,
        autocomplete: 'off',
        autocorrect: 'off',
        autocapitalize: 'off',
        role: props.role,
        ...attrs,
      };

      if (props.type === 'password') {
        attributs.autocomplete = 'new-password';
      }

      if (
        typeof window !== 'undefined' &&
        typeof window.navigator !== 'undefined' &&
        !attributs.autocomplete
      ) {
        const isChrome =
          /Chrome/.test(window.navigator.userAgent) && /Google Inc/.test(window.navigator.vendor);
        attributs.autocomplete = isChrome ? props.random : 'off';
      }

      if (props.type === 'number') {
        attributs.step = props.step;

        if (props.min) {
          attributs.min = props.min;
        }

        if (props.max) {
          attributs.max = props.max;
        }
      }

      if (unref(ellipsis) && !props.old) {
        attributs.readonly = props.readonly;
      }

      if (props.old) {
        attributs.readonly = props.readonly;
      }
      return {
        ...fieldAttrs.value,
        ...attributs,
      };
    });

    const containerGroutClasses = computed(() => {
      let directionIcon = '';
      let displayRightIcon = false;

      if (unref(hasSlotIcon)) {
        if (props.iconLeft && !props.iconRight) {
          directionIcon = 'left';
        } else if (
          (!props.iconLeft && props.iconRight) ||
          (props.iconLeft && props.iconRight) ||
          (!props.iconLeft && !props.iconRight)
        ) {
          directionIcon = 'right';
        }
      }
      if (directionIcon !== 'right' && props.clearButton) {
        displayRightIcon = true;
      }
      return {
        ...unref(groutClasses),
        [`${unref(baseClassName)}__group--left-icon`]: directionIcon === 'left',
        [`${unref(baseClassName)}__group--right-icon`]:
          directionIcon === 'right' || displayRightIcon,
      };
    });

    const rootConf = computed(() => {
      let classes = {
        ...unref(rootClasses),
      };
      if (!props.old) {
        classes = {
          ...classes,
          [`${unref(baseClassName)}--readonly`]: props.readonly,
          [`${unref(baseClassName)}--ios`]: unref(isIOs),
        };
      }
      return {
        class: classes,
      };
    });

    const inputConf = computed(() => {
      return {
        ...unref(inputAttrs),
        ...unref(inputListeners),
        value: modelValue.value,
        ref: 'field',
        class: {
          [`${unref(baseClassName)}__field`]: true,
        },
      };
    });

    // ...methods

    const onClear = () => {
      if (props.readonly || props.disabled) return;

      setTimeout(() => {
        emit('update:modelValue', '');
        emit('clear', '');
        emit('input', '');
        emit('change');
        destroyMask();
      });
    };

    const getButtonIcon = () => {
      const hasClearButton =
        props.modelValue &&
        props.clearButton &&
        !props.loading &&
        !props.disabled &&
        (!unref(hasSlotIcon) || !props.iconRight || (!props.iconLeft && !props.iconRight));

      const clearButton = hasClearButton
        ? [
            h(
              'div',
              {
                class: {
                  [`${unref(baseClassName)}__icon`]: true,
                  [`${unref(baseClassName)}__icon--right`]: true,
                },
              },
              [
                h(
                  'button',
                  {
                    class: {
                      'base-icon-button': true,
                    },
                    type: 'button',
                    tabIndex: -1,
                    onClick: onClear,
                  },
                  [
                    h(IconClose, {
                      fill: props.hasError && !unref(focused) ? '#FA4747' : '#B0B0B0',
                      height: 10,
                      width: 10,
                    }),
                  ],
                ),
              ],
            ),
          ]
        : [];

      const loading = props.loading
        ? [
            h(
              'div',
              {
                class: {
                  [`${unref(baseClassName)}__icon`]: true,
                  [`${unref(baseClassName)}__icon--right`]: true,
                },
              },
              [
                h(BaseLoader, {
                  old: false,
                }),
              ],
            ),
          ]
        : [];

      return unref(hasSlotIcon) &&
        ((props.iconRight && !props.loading) ||
          props.iconLeft ||
          (!props.iconRight && !props.iconLeft))
        ? [
            h(
              'div',
              {
                class: {
                  [`${unref(baseClassName)}__icon`]: true,
                  [`${unref(baseClassName)}__icon--right`]: props.iconRight,
                  [`${unref(baseClassName)}__icon--left`]: props.iconLeft,
                },
              },
              [slots.icon()],
            ),
            ...clearButton,
            ...loading,
          ]
        : [...clearButton, ...loading];
    };
    watch(
      () => props.mask,
      () => {
        if (props.mask && !inputMask.value) {
          import('inputmask').then((Inputmask) => {
            inputMask.value = new Inputmask.default(props.mask, { showMaskOnHover: false });
            initMask();
          });
        }
      },
    );
    /**
     * Публичный метод для ручного вызова фокуса на поле
     */
    const render = () => {
      const inputElement = h('input', inputConf.value);
      // Render еще не отработал, El еще не существет.
      nextTick(() => {
        field.value = inputElement.el;
      });

      const buttonElement = getButtonIcon();

      const placeholderElement = getPlaceholder();
      const containerGroutChildren = [inputElement]
        .concat(buttonElement)
        .concat(placeholderElement);

      const containerGroutConfig = {
        class: containerGroutClasses.value,
      };
      const containerGrout = h('div', containerGroutConfig, containerGroutChildren);

      const errorElement = getError();

      const additionalInfo = getAdditionalInfo();

      return h('div', rootConf.value, [containerGrout].concat(errorElement).concat(additionalInfo));
    };

    return {
      render,
      isIOs,
      ellipsis,
      inputMask,
      inputMaskElement,
      randomValue,
      inputListeners,
      inputAttrs,
      containerGroutClasses,
      rootConf,
      inputConf,
      initMask,
      destroyMask,
      onClear,
      getButtonIcon,
      setEllipsis,
      focus,
    };
  },
  render() {
    return this.render();
  },
});
</script>

<style lang="scss" scoped>
input[type='tel']::placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;

  &:focus {
    opacity: 1;
  }
}
</style>
