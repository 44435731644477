export default {
  name: 'base-props',
  props: {
    name: String,
    placeholder: String,
    modelValue: [String, Number, Object],
    messageError: String,
    id: [String, Number],
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    displayError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: [String, Boolean],
      default() {
        return false;
      },
    },
    additionalInfo: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    old: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Boolean,
      default: false,
    },
    clearButton: {
      type: Boolean,
      default: true,
    },
  },
};
