<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-arrow"
    :class="{
      'icon-arrow--left': direction === 'left',
      'icon-arrow--right': direction === 'right',
      'icon-arrow--top': direction === 'top',
      'icon-arrow--down': direction === 'down',
    }"
  >
    <path
      d="M8 15L1 8L8 1"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconArrow',
  props: {
    color: {
      type: String,
      default: '#232323',
    },
    direction: {
      type: String,
      default: 'left',
    },
    height: {
      type: [String, Number],
      default: '16',
    },
    width: {
      type: [String, Number],
      default: '9',
    },
  },
};
</script>

<style scoped lang="scss">
.icon-arrow {
  transition: $primary-transition;

  &--right {
    transform: rotate(180deg);
  }

  &--top {
    transform: rotate(90deg);
  }

  &--down {
    transform: rotate(270deg);
  }
}
</style>
