<script>
import { h, defineComponent, computed } from 'vue';
import BaseLoader from '@/components/BaseComponents/BaseLoader.vue';
import IconArrow from '@/components/icon-svg/IconArrow.vue';

export default defineComponent({
  name: 'BaseButton',
  props: {
    tag: {
      type: String,
      default() {
        return 'button';
      },
    },
    href: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default() {
        return 'button';
      },
    },
    level: {
      type: [Number, String],
      default() {
        return 0;
      },
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
    quaternary: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: true,
    },
    roundCircle: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    default: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    const level = computed(() => parseInt(props.level, 10));

    const levels = computed(() => {
      return {
        primary: level.value === 1 || props.primary,
        secondary: level.value === 2 || props.secondary,
        tertiary: level.value === 3 || props.tertiary,
        quaternary: level.value === 4 || props.quaternary,
      };
    });

    const outline = computed(() => {
      const res = {};
      if (props.outline) {
        for (const levelsKey in levels.value) {
          if (levels.value[levelsKey]) {
            res[`base-button--${levelsKey}-outline`] = true;
          }
        }
      }

      return res;
    });

    const classes = computed(() => {
      return {
        'base-button': true,
        'base-button--loading': props.loading,
        'base-button--sm': props.sm,
        'base-button--lg': props.lg,
        'base-button--primary': levels.value.primary,
        'base-button--secondary': levels.value.secondary,
        'base-button--tertiary': levels.value.tertiary,
        'base-button--quaternary': levels.value.quaternary,
        'base-button--round': props.round,
        'base-button--round-circle': props.roundCircle,
        'base-button--default': props.default,
        'base-button--icon': props.icon,
        'base-button--grey': props.grey,
        ...outline.value,
      };
    });

    const attrs = computed(() => {
      const res = {
        type: props.tag !== 'a' && props.type,
        disabled: props.loading,
      };
      if (props.href) {
        res.href = props.href;
      }

      return res;
    });

    const buttonLoader = h('div', { class: 'base-button__loader' }, [
      h(BaseLoader, {
        old: false,
        primary: levels.value.primary,
        secondary: levels.value.secondary || levels.value.tertiary || levels.value.quaternary,
      }),
    ]);
    const transition = h('transition', { name: 'base-button__fade' }, [buttonLoader]);
    const arrow = h('div', { class: 'base-button__icon' }, [
      h(IconArrow, {
        direction: 'right',
        width: 6,
        height: 10,
      }),
    ]);
    const render = () => {
      const children = slots?.default ? [slots.default()] : [];
      if (props.loading) {
        children.push(transition);
      }

      if (props.quaternary) {
        children.push(arrow);
      }
      return h(
        props.tag,
        {
          class: classes.value,
          ...attrs.value,
        },
        children,
      );
    };

    return { arrow, classes, transition, attrs, slots, render };
  },

  render() {
    return this.render();
  },
});
</script>
