import { computed, h, onMounted, ref, unref, ComputedRef, SetupContext, VNode } from 'vue';
import { isAppleDevice } from '@/utils/bowser';

// Был миксин, чтобы не дублировать код вынес в композабле
export default function useBaseField(
  props: any,
  { slots }: SetupContext,
  inputField: ComputedRef<HTMLElement | null>,
) {
  const isIOs = ref(false);
  const focused = ref(false);
  const idElement = ref(0);

  const baseClassName = computed(() => {
    return props.old ? 'base-control-old' : 'base-control';
  });

  const groutClasses = computed(() => {
    return {
      [`${baseClassName.value}__group`]: true,
    };
  });

  const rootClasses = computed(() => {
    const rawClassName = baseClassName.value;

    let classes = {
      [rawClassName]: true,
      [`${rawClassName}--disabled`]: props.disabled,
      [`${rawClassName}--ios`]: isIOs.value,
      'base--error': (props.hasError && !focused.value) || (props.hasError && props.displayError),
    };

    if (props.old) {
      classes = {
        ...classes,
        [`${rawClassName}--light`]: props.light,
        [`${rawClassName}--order`]: props.order,
        [`${rawClassName}--full-height`]: props.fullHeight,
        [`${rawClassName}--fixed-error`]: props.errorMessageFixed,
      };
    } else {
      classes = {
        ...classes,
        [`${rawClassName}--focused`]: focused.value,
        [`${rawClassName}--empty`]: !props.modelValue,
        [`${rawClassName}--checked`]: props.checked,
      };
    }
    return classes;
  });

  const fieldAttrs = computed(() => {
    const attributs: Record<string, unknown> = {};
    if (props.name) {
      attributs.name = props.name;
    }
    if (props.placeholder && props.old) {
      attributs.placeholder = props.placeholder;
    }
    if (props.id) {
      attributs.id = props.id;
    }
    if (props.disabled) {
      attributs.disabled = props.disabled;
    }
    return attributs;
  });

  const hasSlotIcon = computed(() => {
    return Boolean(slots.icon);
  });

  const onClickLabel = () => {
    if (!inputField.value) {
      return;
    }

    inputField.value.focus();
  };
  const getPlaceholder = () => {
    const rawClassName = baseClassName.value;

    return props.placeholder && !props.old
      ? [
          h(
            'label',
            {
              class: [
                `${rawClassName}__placeholder`,
                props.required ? `${rawClassName}__placeholder-required` : '',
              ].join(' '),
              tabIndex: '-1',
              for: unref(idElement),
              onClick: onClickLabel,
            },
            [props.placeholder],
          ),
        ]
      : [];
  };

  const getError = () => {
    let message: VNode | string = '';
    if (slots.message) {
      message = slots.message()[0];
    } else if (props.messageError) {
      message = props.messageError;
    }

    return props.hasError && (Boolean(slots.message) || Boolean(props.messageError))
      ? [h('div', { class: `${baseClassName.value}__error-message` }, [message])]
      : [];
  };

  const getAdditionalInfo = () => {
    const has = Boolean(props.additionalInfo) || Boolean(slots.additionalInfo);
    const getContext = () =>
      props.additionalInfo || (slots.additionalInfo && slots.additionalInfo());
    return has && !props.old
      ? [h('div', { class: `${baseClassName.value}__additional-info` }, [getContext()])]
      : [];
  };
  /**
   * Публичный метод для ручного вызова фокуса на поле
   */
  const focus = () => {
    if (!inputField.value) {
      return;
    }

    inputField.value.focus();
  };

  onMounted(() => {
    isIOs.value = isAppleDevice();
    idElement.value = props.id;
  });

  return {
    baseClassName,
    groutClasses,
    rootClasses,
    fieldAttrs,
    hasSlotIcon,
    getPlaceholder,
    getError,
    getAdditionalInfo,
    onClickLabel,
    focus,
    focused,
    isIOs,
    idElement,
  };
}
