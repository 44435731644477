<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    :fill="fill"
  >
    <path
      d="M6.78241 7.5023L0.143027 14.1883C-0.0414258 14.3741 -0.0414258 14.675 0.143027 14.8607C0.235137 14.9537 0.356074 15 0.476778 15C0.597715 15 0.718418 14.9537 0.810528 14.8607L7.49983 8.12445L14.1891 14.8607C14.2815 14.9537 14.4022 15 14.5229 15C14.6436 15 14.7645 14.9537 14.8566 14.8607C15.0411 14.675 15.0411 14.3741 14.8566 14.1883L8.21748 7.5023L14.8611 0.81156C15.0455 0.625811 15.0455 0.324884 14.8611 0.139135C14.6766 -0.0463783 14.3778 -0.0463783 14.1936 0.139135L7.50006 6.88015L0.80584 0.139371C0.621387 -0.0461422 0.322793 -0.0461422 0.13834 0.139371C-0.0461133 0.32512 -0.0461133 0.626047 0.13834 0.811796L6.78241 7.5023Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default() {
        return 15;
      },
    },
    height: {
      type: Number,
      default() {
        return 15;
      },
    },
    fill: {
      type: String,
      default() {
        return '#232323';
      },
    },
  },
};
</script>
