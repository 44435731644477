import basePropsMixin from './base-props.mixin.js';

export default {
  name: 'base-field-mixin',
  mixins: [basePropsMixin],
  props: {
    // deprecated
    old: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
      idElement: null,
    };
  },
};
